<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{$t('label.portList')}}</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="8" class="d-flex justify-content-start align-items-center">
              <div class="file-custom mr-2 d-flex" v-if="false">
                <input 
                  type="file" 
                  ref="file"
                  id="file"
                  class="inputfile"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  @change="handleFileUpload"
                />
                <label for="file" :class="isFileActive">{{$t('label.chooseFile')}}</label>
                <div class="selected">
                  <span class="ml-1 text-muted">{{ fileName }}</span>
                </div>
              </div>
              <CButton
                v-if="false"
                color="primary"
                class="d-flex align-items-center mr-2"
                @click="cargaMasiva"
                :disabled="isDisabled"
              >
                <div v-if="!isSubmitFile">
                  <CIcon name="cil-cloud-upload"/><span class="ml-1">{{$t('label.bulkLoad')}}</span>
                </div>
                <div v-if="isSubmitFile">
                  <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span class="ml-1">{{$t('label.bulkLoad')}}</span>
                </div>
              </CButton>
              <CButton
                color="excel"
                class="d-flex align-items-center"
                @click="verFormato"
                :disabled="isItemsEmpty"
              >
                <CIcon name="excelDownload"/><span class="ml-1">{{$t('label.seeFormat')}}</span>
              </CButton>
            </CCol>
            <CCol sm="4" class="d-flex justify-content-end align-items-center">
              <CButton
                shape="square"
                color="add"
                class="d-flex align-items-center"
                v-c-tooltip="{
                  content: $t('label.port'),
                  placement: 'top-end'
                }"
                @click="toggleAdd"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
              </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="formatedItems"
                :fields="fields"
                :loading="isLoading"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                :active-page="activePage"
                pagination
              >
                <template #loading>
                  <loading/>
                </template>
                <template #RowNumber="{ item }">
                  <td class="center-cell">
                    {{ item.RowNumber }}
                  </td>
                </template>
                <template #PortName="{ item }">
                  <td class="center-cell text-left">
                    {{ item.PortName }}
                  </td>
                </template>
                <template #PortCode="{ item }">
                  <td class="center-cell">
                    {{ item.PortCode }}
                  </td>
                </template>
                <template #CodPais="{ item, index }"><!--d-flex align-items-center  -->
                  <td class="center-cell text-center">
                    <span class="mr-1">{{ item.CodPais }}</span>
                    <img :src="srcImage(item.Icon)" :alt="item.Icon+index"  style="height: 32px !important; width: 32px !important;"  class="img-thumbnail icon" @error="setAltImg"/>
                  </td>
                </template>
                <template #UltimoUsuario="{ item }">
                  <td class="center-cell text-left">
                    {{ item.UltimoUsuario }}
                  </td>
                </template>
                <template #FormatedDate="{ item }">
                  <td class="center-cell">
                    {{ item.FormatedDate }}
                  </td>
                </template>
                <template #Status="{item}">
                  <td class="center-cell">
                    <CBadge :color="getBadge(item.Status)">
                      {{ $t('label.'+item.Status) }}
                    </CBadge>
                  </td>
                </template>

                <template #Details="{item, index}">
                  <td class="py-2 center-cell">
                      <CButton
                        color="plan"                       
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{
                          content: $t('label.assign')+''+$t('label.code'),
                          placement: 'top-end'
                        }"
                        @click="toggleCodes(item, index)"
                      >
                        <CIcon name="cil-tags" />
                      </CButton>
                      <CButton
                        color="edit"
                        size="sm"
                        v-c-tooltip="{
                          content: $t('label.edit')+$t('label.port'),
                          placement: 'top-end'
                        }"
                        @click="toggleEdit(item, index)"
                      >
                        <CIcon name="pencil" />
                      </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    
    <add-puerto-modal :modal.sync="modalAdd" @submited="handleSubmit"/>
    <edit-puerto-modal :modal.sync="modalEdit" :puerto-data="puerto" @submited="handleSubmit"/>
    <add-codes-modal :modal.sync="modalCodes" :puerto-data="puerto"/>
  </CRow>
</template>

<script>
import { DateFormater, GenerateExcel } from '@/_helpers/funciones';
import AddPuertoModal from './add-puerto-modal';
import EditPuertoModal from './edit-puerto-modal';
import AddCodesModal from './add-codes-modal';
import GeneralMixin from '@/_mixins/general';

//Funcion data() del ciclo.
function fields() {
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      filter: false,
      _style: 'width:1%; text-align:center;'
    },
    { key: 'PortName', label:this.$t('label.port'), _style: 'width:37%;', _classes:"text-uppercase" },
    { key: 'PortCode', label: this.$t('label.code'), _style: 'width:10%; text-align:center;', _classes:"text-center" },
    { key: 'CodPais', label: this.$t('label.country'), _style: 'width:10%;', _classes:"text-center" },
    { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width:10%;', _classes:"text-left center-cell"},
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width:10%;', _classes:'text-center center-cell'},
    { key: 'Status', label: this.$t('label.status'), _style: 'width:10%;', _classes:'text-center'},
    { 
      key: 'Details', 
      label: '', 
      _style: 'text-align:right; width:100px;',
      sorter: false, 
      filter: false,

    }
  ];
}

function data() {
  return {
    srcError:'/img/errorimage.jpg',
    total:'',
    lastPage:'',
    perPage:5,
    currentPage:1,
    mainPage:1,
    finalPage:5,
    items: [],
    isSubmitFile: false,
    validFile: false,
    puerto: {},
    isLoading: false,
    modalAdd: false,
    modalEdit: false,
    modalCodes: false,
    activePage: 1,
    archivo: '',
  };
}

//*****Funcion created() del ciclo.
function created() {
  this.getPuertos();
}

//*****Funciones de methods:
function getPuertos() {
  this.isLoading = true;

  this.$http.get('Port-list', { CountryId: '', filter: 'ALL' })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function toggleAdd() {
  this.modalAdd = true;
}
function toggleEdit(item, index) {
  this.puerto = Object.assign({}, item);
  this.modalEdit = true;
}

function toggleCodes(item, index) {
  this.puerto = Object.assign({}, item);
  this.modalCodes = true;
}

function handleSubmit(){
  this.getPuertos();
}

function handleFileUpload() {
  let validTypes = ['xlsx'];

  this.archivo = this.$refs.file.files[0];

  let fileExtension = this.archivo.name.substring(this.archivo.name.lastIndexOf('.')+1, this.archivo.name.length) || '';

  if(validTypes.includes(fileExtension))
    this.validFile = true;
  else{
    this.archivo = '';
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: 'Archivo incorrecto, solo se permiten Excel(.xlsx)',
      type: "error"
    });
  }
}
function verFormato() {
  let excelData = this.items.map(item => Object.assign({}, {
    "Name": item.PortName,
    "Code": item.PortCode,
    "CodIsoCountry": item.CountryJson[0].IsoCodeAlpha3,
    "Latitude": item.Latitude?item.Latitude:'N/A',
    "Longitude":item.Longitude?item.Longitude:'N/A'
  }));
  GenerateExcel(excelData, `PUERTOS`);
  this.$notify({
    group: 'container',
    text: 'Formato Generado!',
    type: "success"
  });
}
function srcImage(icon){
  if(icon === null || icon === '' || icon === undefined){
    return this.srcError;
  }else{
    return icon;
  }
}
function setAltImg(event){
  event.target.src = this.srcError;
}
function cargaMasiva() {
  /*this.isSubmitFile = true;
  let formData = new FormData();
  formData.append("documento", this.archivo);

  PuertoService.cargaMasiva(formData)
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡Exito!',
      text: response.data.data[0].Response,
      type: "success"
    });
    this.getPuertos();
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmitFile = false;
    this.validFile = false;
    this.archivo = '';
  });*/
}

//*****Funcones de computed:
function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    CodPais: item.CountryJson[0].IsoCodeAlpha2?item.CountryJson[0].IsoCodeAlpha2:null,
    Icon:`${this.$store.getters["connection/getBase"]}${item.CountryJson[0].Icon?item.CountryJson[0].Icon.replace('public/', '', 'i'):null}`,
    UltimoUsuario: item.TransaLogin? item.TransaLogin:'N/A',
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}
function fileName(){
  return this.archivo? this.archivo.name:'No File Chosen';
}
function isFileActive(){
  return this.archivo? 'active':'';
}
function isItemsEmpty(){
  return Array.isArray(this.items) && this.items.length == 0;
}
function isDisabled() {
  return !this.validFile || this.isSubmitFile;
}

export default {
  name: 'puertos',
  mixins: [GeneralMixin],
  components: { AddPuertoModal, EditPuertoModal, AddCodesModal },
  data,
  created,
  methods: {
    setAltImg,
    srcImage,
    getPuertos,
    toggleAdd,
    toggleEdit,
    toggleCodes,
    handleSubmit,
    verFormato,
    cargaMasiva,
    handleFileUpload,
  },
  computed: {
    fields,
    formatedItems,
    fileName,
    isFileActive,
    isItemsEmpty,
    isDisabled
  }
}
</script>

<style lang="scss" scoped>
.bandera {
  width: 2em;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  color: #636f83;
  padding: 0.375rem 0.75rem;
  border: 1px solid #636f83;
  border-radius: 0.25rem;
  background-color: white;
  display: inline-block;
  cursor: pointer; /* "hand" cursor */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.inputfile:focus + label,
.inputfile + label:hover {
    color: white;
    background-color: #636f83;
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
	pointer-events: none;
}

label.active {
  color: white;
  background-color: #636f83;
}

.file-custom label {
  margin-bottom: 0;
}
.file-custom .selected {
  display: flex;
  align-items: center;
  max-width: 100px;
  /* background: black; */
}
.file-custom .selected span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.center-cell {
  text-align: center;
}
</style>